import React, { useState, useEffect, useCallback } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'; // Import arrow icons
import './QuoteCarousel.css';
import quotes from './quotesData'; // Import the quotes array


const QuoteCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(() => {
    const length = quotes.length;
    return Math.floor(Math.random() * length);
  });

  const nextQuote = useCallback(() => {
    setCurrentIndex((currentIndex + 1) % quotes.length);
  }, [currentIndex]);
  
  const prevQuote = useCallback(() => {
    setCurrentIndex((currentIndex - 1 + quotes.length) % quotes.length);
  }, [currentIndex]);

  const handleKeyboardEvent = useCallback((e) => {
    switch (e.key) {
      case 'ArrowLeft':
        prevQuote();
        break;
      case 'ArrowRight':
        nextQuote();
        break;
      default:
        break;
    }
  }, [nextQuote, prevQuote]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyboardEvent);
    return () => {
      window.removeEventListener('keydown', handleKeyboardEvent);
    };
  }, [currentIndex, handleKeyboardEvent]);

  return (
    <div className="quote-carousel">
      <div className="arrow left" onClick={prevQuote}>
        <FaArrowLeft />
      </div>
      <p className="quote-text">
        <q>{quotes.length > 0 ? quotes[currentIndex].text : 'Loading...'}</q>
        <p>
        <cite>- {quotes.length > 0 ? quotes[currentIndex].author : 'Loading...'}</cite></p>
      </p>
      <div className="arrow right" onClick={nextQuote}>
        <FaArrowRight />
      </div>
    </div>
  );
};

export default QuoteCarousel;
