import React from 'react';
import { Helmet } from 'react-helmet';
import './App.css';
import Banner from './Banner'; // Import the Banner component
import QuoteCarousel from './QuoteCarousel'; // Import the QuoteCarousel component
import Disclaimer from './Disclaimer'; // Import the QuoteCarousel component

function App() {
  return (
    <div className="App">
      <Helmet>
        {/* Add your Google Analytics script here */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-FEWZ0VBKDZ"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', 'G-FEWZ0VBKDZ');
          `}
        </script>
        <title>Eckhart Tolle Quotes</title>
        <link rel="icon" href="/eckhart.ico" />
      </Helmet>
      <Banner /> {/* Include the Banner component here */}
      <div className="QuoteCarousel"> {/* Add QuoteCarousel class */}
        <QuoteCarousel />
      </div>
      <Disclaimer />
    </div>
  );
}

export default App;
