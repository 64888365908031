// Disclaimer.js
import React from 'react';

function Disclaimer() {
    return (
        <div className="disclaimer">
            <p> Our website, eckhartquotes.com, features quotes attributed to Eckhart Tolle. We are not endorsed or affiliated with Eckhart Tolle or its related official affiliations. While we strive for accuracy, we do not guarantee the absolute precision or context of each quote. All quotes are used under fair use for educational and commentary purposes. Users are encouraged to verify information independently. Contact us with concerns at eckhartquotes@gmail.com.
            </p>
        </div>
    );
}

export default Disclaimer;
