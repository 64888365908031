const quotes = [
    {
        "id": 0,
        "text": "The past has no power over the present moment.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 1,
        "text": "Some changes look negative on the surface but you will soon realize that space is being created in your life for something new to emerge.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 2,
        "text": "The primary cause of unhappiness is never the situation but your thoughts about it.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 3,
        "text": "Acknowledging the good that you already have in your life is the foundation for all abundance.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 4,
        "text": "Give up defining yourself - to yourself or to others. You won't die. You will come to life. And don't be concerned with how others define you. When they define you, they are limiting themselves, so it's their problem. Whenever you interact with people, don't be there primarily as a function or a role, but as the field of conscious Presence. You can only lose something that you have, but you cannot lose something that you are.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 5,
        "text": "Life will give you whatever experience is most helpful for the evolution of your consciousness. How do you know this is the experience you need? Because this is the experience you are having at the moment.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 6,
        "text": "Sometimes letting things go is an act of far greater power than defending or hanging on.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 7,
        "text": "To love is to recognize yourself in another.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 8,
        "text": "Life isn't as serious as the mind makes it out to be.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 9,
        "text": "Realize deeply that the present moment is all you have.  Make the NOW the primary focus of your life.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 10,
        "text": "Life is the dancer and you are the dance.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 11,
        "text": "You find peace not by rearranging the circumstances of your life, but by realizing who you are at the deepest level.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 12,
        "text": "You can only lose something that you have, but you cannot lose something that you are.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 13,
        "text": "Anything that you resent and strongly react to in another is also in you.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 14,
        "text": "A genuine relationship is one that is not dominated by the ego with its image-making and self-seeking.  In a genuine relationship, there is an outward flow of open, alert attention toward the other person in which there is no wanting whatsoever.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 15,
        "text": "Whatever you fight, you strengthen, and what you resist, persists.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 16,
        "text": "The primary cause of unhappiness is never the situation but thought about it. Be aware of the thoughts you are thinking. Separate them from the situation, which is always neutral. It is as it is.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 17,
        "text": "Death is a stripping away of all that is not you. The secret of life is to \"die before you die\" ---  and find that there is no death.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 18,
        "text": "Being spiritual has nothing to do with what you believe and everything to do with your state of consciousness.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 19,
        "text": "Time isn't precious at all, because it is an illusion. What you perceive as precious is not time but the one point that is out of time: the Now. That is precious indeed. The more you are focused on time \u2013past and future\u2013 the more you miss the Now, the most precious thing there is.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 20,
        "text": "Worry pretends to be necessary but serves no useful purpose.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 21,
        "text": "Love is not selective, just as the light of the sun is not selective. It does not make one person special. It is not exclusive. Exclusivity is not the love of God but the \"love\" of ego. However, the intensity with which true love is felt can vary. There may be one person who reflects your love back to you more clearly and more intensely than others, and if that person feels the same toward you, it can be said that you are in a love relationship with him or her. The bond that connects you with that person is the same bond that connects you with the person sitting next to you on a bus, or with a bird, a tree, a flower. Only the degree of intensity with which it is felt differs.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 22,
        "text": "Is there a difference between happiness and inner peace? Yes. Happiness depends on conditions being perceived as positive; inner peace does not.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 23,
        "text": "This, too, will pass.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 24,
        "text": "Accept - then act. Whatever the present moment contains, accept it as if you had chosen it. Always work with it, not against it.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 25,
        "text": "Pleasure is always derived from something outside you, whereas joy arises from within.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 26,
        "text": "All true artists, whether they know it or not, create from a place of no-mind, from inner stillness.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 27,
        "text": "You do not become good by trying to be good, but by finding the goodness that is already within you, and allowing that goodness to emerge. But it can only emerge  if something fundamental changes in your state of consciousness.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 28,
        "text": "Always say yes to the present moment. What could be more futile, more insane, than to create inner resistance to what already is? what could be more insane than to oppose life itself, which is now and always now? Surrender to what is. Say yes to life \u2014 and see how life suddenly starts working for you rather than against you.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 29,
        "text": "Love is a state of Being. Your love is not outside; it is deep within you. You can never lose it, and it cannot leave you.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 30,
        "text": "Knowing yourself is to be rooted in Being, instead of lost in your mind.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 31,
        "text": "Become conscious of being conscious.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 32,
        "text": "It is when we are trapped in incessant streams of compulsive thinking that the universe really disintegrates for us, and we lose the ability to sense the interconnectedness of all that exists.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 33,
        "text": "...whenever there is inspiration...and enthusiasm...there is a creative empowerment that goes far beyond what a mere person is capable of.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 34,
        "text": "When another recognizes you, that recognition draws the dimension of Being more fully into this world through both of you.  That is the love that redeems the world.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 35,
        "text": "Nothing ever happened in the past that can prevent you from being present now, and if the past cant prevent you from being present now, what power does it have?",
        "author": "Eckhart Tolle"
    },
    {
        "id": 36,
        "text": "You do not become good by trying to be good, but by finding the goodness that is already within you, and allowing that goodness to emerge.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 37,
        "text": "every complaint is a little story the mind makes up that you completely believe in.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 38,
        "text": "You have so much to learn from your enemies.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 39,
        "text": "Defining yourself through thought is limiting yourself.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 40,
        "text": "The human condition: lost in thought.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 41,
        "text": "Words reduce reality to something the human mind can grasp, which isn't very much.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 42,
        "text": "The ego wants to want more than it wants to have.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 43,
        "text": "Authentic human interactions become impossible when you lose yourself in a role.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 44,
        "text": "Awareness is the power that is concealed within the present moment. \u2026 The ultimate purpose of human existence, which is to say, your purpose, is to bring that power into this world.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 45,
        "text": "The beginning of freedom is the realization that you are not the thinker. The moment you start watching the thinker, a higher level of consciousness becomes activated. You then begin to realize that there is a vast realm of intelligence beyond thought, that thought is only a tiny aspect of that intelligence. You also realize that all the things that truly matter \u2013 beauty, love, creativity, joy, inner peace \u2013 arise from beyond the mind. You begin to awaken.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 46,
        "text": "The most common ego identifications have to do with possessions, the work you do, social status and recognition, knowledge and education, physical appearance, special abilities, relationships, person and family history, belief systems, and often nationalistic, racial, religious, and other collective identifications. None of these is you.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 47,
        "text": "Doing is never enough if you neglect Being.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 48,
        "text": "One thing we do know: Life will give you whatever experience is most helpful for the evolution of your consciousness. How do you know this is the experience you need? Because this is the experience you are having at this moment.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 49,
        "text": "You become most powerful in whatever you do if the action is performed for its own sake rather than as a means to protect, enhance, or conform to your role identity.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 50,
        "text": "What will be left of all the fearing and wanting associated with your  problematic life situation that every day takes up most of your attention? A  dash, one or two inches long, between the date of birth and date of death on  your gravestone.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 51,
        "text": "The greatest achievement of humanity is not its works of art, science, or technology, but the recognition of its own dysfunction.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 52,
        "text": "Realize deeply that the present moment is all you ever have. Make the Now the primary focus of your life",
        "author": "Eckhart Tolle"
    },
    {
        "id": 53,
        "text": "Nonresistance is the key to the greatest power in the universe.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 54,
        "text": "Life isn't as serious as my mind makes it out to be.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 55,
        "text": "To recognize one's own insanity is, of course, the arising of sanity, the beginning of healing and transcendence.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 56,
        "text": "On a deeper level you are already complete. When you realize that, there is a joyous energy behind what you do.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 57,
        "text": "Power over others is weakness disguised as strength.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 58,
        "text": "Even a stone, and more easily a flower or a bird, could show you the way back to God, to the Source, to yourself. When you look at it or hold it and let it be without imposing a word of mental label on it, a sense of awe, of wonder, arises within you. Its essence silently communicates itself to you and reflects your own essence back to you.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 59,
        "text": "All negativity is caused by an accumulation of psychological time and denial of the present. Unease, anxiety, tension, stress, worry - all forms of fear - are caused by too much future, and not enough presence. Guilt, regret, resentment, grievances, sadness, bitterness, and all forms of nonforgiveness are caused by too much past, and not enough presence.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 60,
        "text": "In the eyes of the ego, self-esteem and humility are contradictory.  In truth, they are one and the same.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 61,
        "text": "Your outer journey may contain a million steps; your inner journey only has one: the step you are taking right now.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 62,
        "text": "Through the present moment, you have access to the power of life itself, that which has traditionally been called \"God.\"   As soon as you turn away from it, God ceases to be a reality in your life, and all you are left with is the mental concept of God, which some people believe in and others deny. Even belief in God is only a poor substitute for the living reality of God manifesting every moment of your life.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 63,
        "text": "Words reduce reality to something the human mind can grasp, which isn't very much. Language consists of five basic sounds produced by the vocal cords. They are the vowels a, e, i, o, u. The other sounds are consonants produced by air pressure: s, f, g, and so forth. Do you believe some combination of such basic sounds could ever explain who you are, or the ultimate purpose of the universe, or even what a tree or stone is in its depth?",
        "author": "Eckhart Tolle"
    },
    {
        "id": 64,
        "text": "Nothing has happened in the past; it happened in the Now.  Nothing will ever happen in the future; it will happen in the Now.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 65,
        "text": "I have lived with several Zen masters -- all of them cats.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 66,
        "text": "Once you have had a glimpse of awareness or Presence, you know it firsthand.  It is no longer just a concept in your mind.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 67,
        "text": "With stillness comes the benediction of Peace.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 68,
        "text": "The brain does not create consciousness, but conciousness created the brain, the most complex physical form on earth, for its expression.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 69,
        "text": "If your mind carries a heavy burden of past, you will experience more of the same. The past perpetuates itself through lack of presence. The quality of your consciousness at this moment is what shapes the future.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 70,
        "text": "Reading is my passion and my escape since I was 5 years old. Overall, children don't realize the magic that can live inside their own heads. Better even then any movie.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 71,
        "text": "the primary cause of unhappiness is never the situation but you thoughts about it.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 72,
        "text": "If you identify with a mental position, then if you are wrong, your mind-based sense of self is seriously threatened with annihilation. So you as the ego cannot afford to be wrong. To be wrong is to die. Wars have been fought over this, and countless relationships have broken down.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 73,
        "text": "You are here to enable the divine purpose of the Universe to unfold. That is how important you are!",
        "author": "Eckhart Tolle"
    },
    {
        "id": 74,
        "text": "If her past were your past, her pain your pain, her level of consciousness your level of consciousness, you would think and act exactly as she does. With this realization comes forgiveness, compassion and peace.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 75,
        "text": "That in you which recognizes madness as madness (even if it is your own) is sanity, is the arising awareness, is the end of insanity.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 76,
        "text": "The mind is a superb instrument if used rightly. Used wrongly, however, it becomes very destructive. To put it more accurately, it is not so much that you use your mind wrongly\u2014you usually don't use it at all. It uses you. All the things that truly matter \u2014 beauty, love, creativity, joy, inner peace \u2014 arise from beyond the mind...",
        "author": "Eckhart Tolle"
    },
    {
        "id": 77,
        "text": "Prejudice of any kind implies that you are identified with the thinking mind. It means you don't see the other human being anymore, but only your own concept of that human being",
        "author": "Eckhart Tolle"
    },
    {
        "id": 78,
        "text": "Practice presence -- embrace the place where life happens.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 79,
        "text": "The pas has no power to stop you from being present now.  Only your grievance about the past can do that.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 80,
        "text": "The foundation of greatness is honoring the small things of the present moment, instead of pursuing the idea of greatness.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 81,
        "text": "Those who have not found their true wealth, which is the radiant joy of Being and the deep, unshakable peace that comes with it, are beggars, even if they have great material wealth.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 82,
        "text": "Negativity is totally unnatural. It is a psychic pollutant, and there is a deep link between the poisoning and destruction of nature and the vast negativity that has accumulated in the collective human psyche. No other life-form on the planet knows negativity, only humans, just as no other life-form violates and poisons the Earth that sustains it. Have you ever seen an unhappy flower or a stressed oak tree? Have you some across a depressed dolphin, a frog that has a problem with self-esteem, a cat that cannot relax, or a bird that carries hatred and resentment? The only animals that may occasionally experience something akin to negativity or show signs of neurotic behavior are those that live in close contact with humans and so link into the humans mind and its insanity.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 83,
        "text": "Each person's life \u2013 each lifeform, in fact \u2013 represents a world, a unique way in which the universe experiences itself.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 84,
        "text": "The quicker you are in attaching verbal or mental labels to things, people, or situations, the more shallow and lifeless your reality becomes, and the more deadened you become to reality.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 85,
        "text": "You are not IN the universe, you ARE the universe, an intrinsic part of it. Ultimately you are not a person, but a focal point where the universe is becoming conscious of itself. What an amazing miracle.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 86,
        "text": "Not to be able to stop thinking is a dreadful affliction, but we don't realize this because almost everyone is suffering from it, so it is considered normal. This incessant mental noise prevents you from finding that realm of inner stillness that is inseparable from Being.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 87,
        "text": "All you realy need to do is accept this moment fully. You are then at ease in the here and now and at ease with yourself.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 88,
        "text": "The story of the Zen Master whose only response was always \"Is that so?\" shows the good that comes through inner nonresistance to events, that is to say, being at one with what happens. The story of the man whose comment was invariably a laconic \"Maybe\" illustrates the wisdom of nonjudgment, and the story of the ring points to the fact of impermanence which, when recognized, leads to nonattachment. Nonresistance, nonjudgement, and nonattachment are the three aspects of true freedom and enlightened living.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 89,
        "text": "If small things have the power to disturb you, then who you think you are is exactly that: small.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 90,
        "text": "True Power is within, and it is available now.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 91,
        "text": "What a caterpillar calls the end of the world we call a butterfly.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 92,
        "text": "I don't want it to end, and so, as every therapist knows, the ego does not want an end to its \"problems\" because they are part of its identity. If no one will listen to my sad story, I can tell it to myself in my head, over and over, and feel sorry for myself, and so have an identity as someone who is being treated unfairly by life or other people, fate or God. It gives definition to my self-image, makes me into someone, and that is all that matters to the ego.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 93,
        "text": "Watch any plant or animal and let it teach you acceptance of what is, surrender to the Now. Let it teach you Being. Let it teach you integrity \u2014 which means to be one, to be yourself, to be real. Let it teach you how to live and how to die, and how not to make living and dying into a problem.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 94,
        "text": "Man Made 'God' in his own image.  The eternal, the infinate, the unnameable was reduced to a mental idol that you had to believe in and worship as 'my god' or 'our god'.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 95,
        "text": "Nothing has ever happened in the past that can prevent you from being present now; and if the past cannot prevent you from being present now, what power does it have?",
        "author": "Eckhart Tolle"
    },
    {
        "id": 96,
        "text": "And then there is the universal role of adult.  When you play that role, you take yourself and life very seriously.  Spontaneity, lightheartedness, and joy are not part of that role.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 97,
        "text": "Inner resistance to whatever arises in the present moment pulls you back into unconsciousness. Inner resistance is some form of negativity, complaining, fear, aggression, or anger. This is important because whenever you complain about what somebody else does you're already beginning to fall into that trap of unconsciousness.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 98,
        "text": "Once you have identified with some form of negativity, you do not want to let it go, and on a deeply unconscious level, you do not want positive change. It would threaten your identity as a depressed, angry or hard-done by person. You will then ignore, deny or sabotage the positive in your life. This is a common phenomenon. \nIt is also insane.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 99,
        "text": "Love, joy, and peace cannot flourish until you have freed yourself from mind dominance.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 100,
        "text": "Fulfill me, make me happy, make me feel safe, tell me who I am. The world cannot give you those things, and when you no longer have such expectations, all self created suffering comes to an end.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 101,
        "text": "It is precisely through the onset of old age, through loss or personal tragedy, that the spiritual dimension would traditionally come into people's lives. This is to say, their inner purpose would emerge only as their outer purpose collapsed and the shell of the ego would begin to crack open. The emphasis shifts from doing to Being, and our civilization, which is lost in doing, knows nothing of Being. It asks: being? What do you do with it?",
        "author": "Eckhart Tolle"
    },
    {
        "id": 102,
        "text": "When this delusion of utter separateness underlies and governs whatever I think, say, and do, what kind of world do I create?",
        "author": "Eckhart Tolle"
    },
    {
        "id": 103,
        "text": "A significant portion of the earth's population will soon recognize, if they haven't already done so, that humanity is now faced with a stark choice: Evolve or die.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 104,
        "text": "When faced with a radical crisis, when the old way of being in the world, of interacting with each other and with the realm of nature doesn't work anymore, when survival is threatened by seemingly insurmountable problems, an individual life-form -- or a species -- will either die or become extinct or rise above the limitations of its condition through an evolutionary leap.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 105,
        "text": "If you are in a state of intense presence you are free of thought, yet highly alert. If your conscious attention sinks below a certain level, thought rushes in, the mental noise returns, stillness is lost, you're back in time.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 106,
        "text": "Focus attention on the feeling inside you. Know that it is the pain-body. Accept that it is there. Don't think about it - don't let the feeling turn into thinking. Don't judge or analyze. Don't make an identity for yourself out of it. Stay present, and continue to be the observer of what is happening inside you. Become aware not only of the emotional pain but also of \"the one who observes,\" the silent watcher. This is the power of the Now, the power of your own conscious presence. Then see what happens.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 107,
        "text": "Throughout history humans have inflicted countless violent, cruel, and hurtful acts on each other, and continue to do so. Are they all to be condemned; are they all guilty? Or are those acts simply expressions of unconsciousness, an evolutionary stage that we are now growing out of? Jesus\u2019 words, Forgive them for they do not know what they do, also apply to yourself.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 108,
        "text": "Die to the past every moment. You don't need it. Only refer to it when it is absolutely relevant to the present. Feel the power of this moment and the fullness of Being. Feel your presence.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 109,
        "text": "All things that truly matter - beauty , love , creativity ,joy , inner-peace - arise from beyond the mind .",
        "author": "Eckhart Tolle"
    },
    {
        "id": 110,
        "text": "Deny thy self!",
        "author": "Eckhart Tolle"
    },
    {
        "id": 111,
        "text": "You may remember the paradox of time we mentioned earlier: Whatever you do takes time, and yet it is always now. So while your inner purpose is to negate time, your outer purpose necessarily involves future and so could not exist without time. But it is always secondary. Whenever you become anxious or stressed, outer purpose has taken over, and you lost sight of your inner purpose. You have forgotten that your state of consciousness is primary, all else secondary.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 112,
        "text": "If increased meta-knowledge is not counter-balanced by a corresponding growth in consciousness then the likelihood of psycho-spiritual dysfunction is great.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 113,
        "text": "Form is emptiness, emptiness is form\" states the Heart Sutra, one of the best known ancient Buddhist texts. The essence of all things is emptiness.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 114,
        "text": "When you let go of the belief that you should or need to know who you are, what happens to confusion? Suddenly it is gone. When you fully accept that you don't know, you actually enter a state of peace and clarity that is closer to who you truly are than thought could ever be. Defining yourself through thought is limiting yourself.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 115,
        "text": "The key is to be in a state of permanent connectedness with your inner body - to feel it at all times. This will rapidly deepen and transform your life. The more consciousness you direct into the inner body, the higher its vibrational frequency becomes.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 116,
        "text": "The present moment is all you will ever have.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 117,
        "text": "Being an outsider to some extent, someone who does not \"fit in\" with others or is rejected by them for whatever reason, makes life difficult, but it also places you at an advantage as far as enlightenment is concerned.  It takes you out of unconsciousness almost by force.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 118,
        "text": "Space and silence are two aspects of the same thing. The same no-thing. They are externalization of inner space and inner silence, which is stillness: the infinitely creative womb of all existence.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 119,
        "text": "The ego says, 'I shouldn't have to suffer,' and that thought makes you suffer so much more. It is a distortion of the truth, which is always paradoxical. The truth is that you need to say yes to suffering before you can transcend it.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 120,
        "text": "The pain that you create now is always some form of nonacceptance, some form of unconscious resistance to what is. On the level of thought, the resistance is some form of judgment. On the emotional level, it is some form of negativity. The intensity of the pain depends on the degree of resistance to the present moment, and this in turn depends on how strongly you are identified with your mind. The mind always seeks to deny the Now and to escape from it. In other words, the more you are identified with your mind, the more you suffer. Or you may put it like this: the more you are able to honor and accept the Now, the more ore you are free of pain, of suffering - and free of the egoic mind. Why does the mind habitually deny or resist the Now? Because it cannot function and remain in control without time, which is past and future, so it perceives the timeless Now as threatening. Time and mind are in fact inseparable.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 121,
        "text": "So love is the recognition of oneness in a world of duality. This is the birth of God into the world of form. Love makes the world less worldly, less dense, more transparent to the divine dimension, the light of consciousness itself.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 122,
        "text": "Acceptance of the unacceptable is the greatest source of grace in this world.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 123,
        "text": "Rather than being your thoughts and emotions, be the awareness behind them.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 124,
        "text": "The philosopher Descartes believed he had found the most fundamental truth when he made his famous statement: \"I think, therefore I am.\" He had, in fact, given expression to the most basic error: to equate thinking with Being and identity with thinking. The compulsive thinker, which means almost everyone, lives in a state of apparent separateness, in an insanely complex world of continuous problems and conflict, a world that reflects the ever-increasing fragmentation of the mind.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 125,
        "text": "How do you let go of attachment to things? Don't even try. It's impossible. Attachment to things drops away by itself when you no longer seek to find yourself in them.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 126,
        "text": "There is only one perpetrator of evil on the planet: human unconsciousness.  That realization is true forgiveness.  With forgiveness, your victim identity dissolves, and your true power emerges--the power of Presence.  Instead of blaming the darkness, you bring in the light.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 127,
        "text": "You are not separate from the whole. You are one with the sun, the earth, the air. You don't have a life. You are life.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 128,
        "text": "In essence, you are neither inferior nor superior to anyone.  True self-esteem and true humility arise out of that realization.  In the eyes of the ego, self-esteem and humility are contradictory.  In truth, they are one and the same.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 129,
        "text": "Thinking is only a small aspect of consciousness. Thought cannot exist without consciousness, but consciousness does not need thought.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 130,
        "text": "The more shared past there is in a relationship, the more present you need to be.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 131,
        "text": "Suffering needs time. It cannot survive in the now.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 132,
        "text": "\"The best indicator of your level of consciousness is how you deal with life\u2019s challenges when they come. Through those challenges, an already unconscious person tends to become more deeply unconscious, and a conscious person more intensely conscious. You can use a challenge to awaken you, or you can allow it to pull you into even deeper sleep. The dream of ordinary unconsciousness then turns into a nightmare.\"",
        "author": "Eckhart Tolle"
    },
    {
        "id": 133,
        "text": "If there isn't an emanation of love and joy, complete presence and openness toward all beings, then it is not enlightenment.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 134,
        "text": "Emotions arise in the place where your mind and body meet.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 135,
        "text": "Life has no opposite. The opposite of death is birth.  Life is eternal.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 136,
        "text": "\"Failure lies concealed in every success, and success in every failure.\"",
        "author": "Eckhart Tolle"
    },
    {
        "id": 137,
        "text": "To love is to recognize yourself in another.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 138,
        "text": "A powerful spiritual practice is consciously to allow the diminishment of ego when it happens without attempting to restore it.  I recommend that you experiment with this from time to time.  For example, when someone criticizes you, blames you, or calls you names, instead of immediately retaliating or defending yourself ? do nothing.  Allow the self-image to remain diminished and become alert to what that feels like deep inside you.  For a few seconds, it may feel uncomfortable, as if you had shrunk in size.  Then you may sense an inner speciousness that feels intensely alive.  You haven't been diminished at all.  In fact, you have expanded.  You may then come to an amazing realization: When you are seemingly diminished in some way and remain in absolute non-reaction, not just externally but also internally, you realize that nothing real has been diminished, that through becoming \"less,\" you become more.  When you no longer defend or attempt to strengthen the form of yourself, you step out of identification with form, with mental self-image.  Through becoming less (in the ego's perception), you in fact undergo an expansion and make room for Being to come forward.  True power, who you are beyond form, can then shine through the apparently weakened form.  This is what Jesus means when he says, \"Deny yourself\" or \"Turn the other cheek.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 139,
        "text": "Pain can only feed on pain. Pain cannot feed on joy. It finds it quite indigestible.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 140,
        "text": "... there are two ways of being unhappy. Not getting what you want is one. Getting what you want is the other.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 141,
        "text": "The word God has become empty of meaning through thousands of years of misuse... By misuse, I mean that people who have never glimpsed the realm of the sacred, the infinite vastness behind that word, use it with great conviction, as if they knew what they are talking about. Or they argue against it, as if they knew what it is they are denying. This misuse gives rise to absurd beliefs, assertions, and egoic delusions, such as \"My or our God is the only true God, and your God is false,\" or Nietzsche's famous statmeent \"God is dead.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 142,
        "text": "Many people don't realize until they are on their deathbed and everything external falls away that no thing ever had anything to do with who they are.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 143,
        "text": "What you think of as they past is a memory trace, stored in the mind, of a former Now. When you remember the past, you reactivate a memory trace -- and you do so now. The future is an imagined Now, a projection of the mind. When the future comes, it comes as the Now. When you think about the future, you do it now. Past and future obviously have no reality of their own. Just as the moon has no light of its own, but can only reflect the light of the sun, so are past and future only pale reflections of the light, power, and reality of the eternal present. Their reality is \"borrowed\" from the Now.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 144,
        "text": "By a monstrous act of reductionism, the infinite depth of who you are is confused with a sound produced by the vocal cords.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 145,
        "text": "Everything is honored, but nothing matters.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 146,
        "text": "Die before you die and find that there is no death.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 147,
        "text": "Ego-identification with things creates attachment to things, which in turn creates our consumer society and economic structures where the only measure of progress is always more. The unchecked striving for more, for endless growth, is a dysfunction and a disease. It is the same dysfunction the cancerous cell manifests, whose only goal is to multiply itself, unaware that it is bringing about its own destruction by destroying the organism of which it is a part. Some economists are so attached to the notion of growth that they can't let go of that word, so they refer to recession as a time of \"negative growth\".",
        "author": "Eckhart Tolle"
    },
    {
        "id": 148,
        "text": "The ultimate truth of who you are is not I am this or I am that, but I Am.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 149,
        "text": "And then one day, you too disappear. Your armchair is still there. But instead of you sitting in it, there is just an empty space. You went back to where you came from just a few years ago.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 150,
        "text": "Instead of asking \u2018what do I want from life?,\u2019 a more powerful question is, \u2018what does life want from me?\u2019",
        "author": "Eckhart Tolle"
    },
    {
        "id": 151,
        "text": "Resistance is weakness and fear masquerading as strength. What the ego sees as weakness is your Being in its purity, innocence, and power. What it sees as strength is weakness.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 152,
        "text": "Just as the dog loves to chew bones, the human mind loves its problems.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 153,
        "text": "Whatever you accept completely, you go beyond...If you fight it, you're stuck with it.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 154,
        "text": "When you no longer perceive the world as hostile, there is no more fear, and when there is no more fear, you think, speak and act differently.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 155,
        "text": "Humans have learned to split the atom. Instead of killing ten or twenty people with a wooden club, one person can now kill a million just by pushing a button. Is that real change?",
        "author": "Eckhart Tolle"
    },
    {
        "id": 156,
        "text": "Consciousness is a vast ocean and thinking is the waves and ripples on the surface of the ocean. every wave and ripple has a very short lived life - it is very fleeting. do not identify with your thoughts.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 157,
        "text": "You are not your mind!",
        "author": "Eckhart Tolle"
    },
    {
        "id": 158,
        "text": "Be the silent watcher of your thoughts and behavior. You are beneath the thinker. You are the stillness beneath the mental noise. You are the love and joy beneath the pain.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 159,
        "text": "Just watching an animal closely can take you out of your mind and bring you into the present moment, which is where the animal lives all the time --surrendered to life.",
        "author": "Eckhart Tolle"
    },
    {
        "id": 160,
        "text": "The mind is a superb instrument if used rightly. Used wrongly, however, it becomes very destructive.",
        "author": "Eckhart Tolle"
    }
];
  
  export default quotes;
  